import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function IWillRemain() {
  return (
    <Layout>
      <Helmet title="I Will Rremain | Matthew & The Atlas" />

      <h1>I Will Rremain</h1>

      <h2>Matthew & The Atlas</h2>

      <h4>Key: G | Capo 8</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>G</Ch>Further from my widowed home take the <Ch>C</Ch>road that
            sets it to the <Ch>G</Ch>sun
          </p>
          <p>
            <Ch>G</Ch>Waiting for my skin and bone to <Ch>C</Ch>return and see
            what I've <Ch>G</Ch>become
          </p>
          <p>
            <Ch>Em</Ch>Summer has not yet been here <Ch>C</Ch>though my days are
            <Ch>G</Ch>long
          </p>
          <p>
            <Ch>Em</Ch>Take me back to when the night was young and <Ch>C</Ch>
            another song was <Ch>G</Ch>sung
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>G</Ch>What of all those pretty tales the <Ch>C</Ch>ones that
            took me to this <Ch>G</Ch>door
          </p>
          <p>
            Is it <Ch>G</Ch>comfort in the salvers way is it <Ch>C</Ch>comfort
            upon this wooden <Ch>G</Ch>floor
          </p>
          <p>
            <Ch>Em</Ch>Tell you is it a failure to <Ch>C</Ch>forget the ones
            that self <Ch>G</Ch>it
          </p>
          <p>
            And <Ch>Em</Ch>I tried to remember but my <Ch>C</Ch>mind is no
            longer <Ch>G</Ch>clean
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>Em</Ch>Oooh Ooooh <Ch>C</Ch>Oooh, <Ch>Am</Ch>You recognise{" "}
            <Ch>C</Ch>me
          </p>
          <p>
            (Oooh) <Ch>Em</Ch>I follow <Ch>C</Ch>from, far <Ch>Am</Ch>from{" "}
            <Ch>C</Ch>the trees
          </p>
          <p>
            Oooh,<Ch>Em</Ch> The woods<Ch>C</Ch> came out, <Ch>Am</Ch>on to my
            <Ch>C</Ch>knees
          </p>
          <p>
            <Ch>Em</Ch>I twis<Ch>C</Ch>ted <Ch>Am</Ch>round
          </p>
          <p>
            I made no <Ch>C</Ch>sound, No <Ch>G</Ch>sound
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>G</Ch>I'll wonder till my frittle bones has <Ch>C</Ch>come and
            pulled me to the <Ch>G</Ch>stone
          </p>
          <p>
            <Ch>G</Ch>Further from the towns you've known I'll <Ch>C</Ch>remain
            from where I have <Ch>G</Ch>begun
          </p>
          <p>
            <Ch>Em</Ch>Summer before I start, I was <Ch>C</Ch>kicking home
            stones as a <Ch>G</Ch>child
          </p>
          <p>
            <Ch>Em</Ch>In the meadow when the storm came through and I{" "}
            <Ch>C</Ch>followed straight back to <Ch>G</Ch>you
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>Em</Ch>Oooh Ooooh <Ch>C</Ch>Oooh, <Ch>Am</Ch>You recognise{" "}
            <Ch>C</Ch>me
          </p>
          <p>
            Oooh, <Ch>Em</Ch>I follow <Ch>C</Ch>from, far <Ch>Am</Ch>from{" "}
            <Ch>C</Ch>the trees
          </p>
          <p>
            Oooh,<Ch>Em</Ch> The woods<Ch>C</Ch> came out, <Ch>Am</Ch>on to my
            <Ch>C</Ch>knees
          </p>
          <p>
            <Ch>Em</Ch>I twis<Ch>C</Ch>ted <Ch>Am</Ch>round
          </p>
          <p>
            I made no <Ch>C</Ch>sound, No <Ch>G</Ch>sound
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>G</Ch>Would you ever doubt my love <Ch>C</Ch>when my day is{" "}
            <Ch>G</Ch>done
          </p>
          <p>
            <Ch>G</Ch>I'll lay down my body with<Ch>C</Ch>in this earth I've{" "}
            <Ch>G</Ch>won
          </p>
          <p>
            <Ch>Em</Ch>Would you try to follow the <Ch>C</Ch>roads I had to{" "}
            <Ch>G</Ch>walk
          </p>
          <p>
            <Ch>Em</Ch>There's a whisper in the willow <Ch>C</Ch>for they all
            hear me <Ch>G</Ch>talk
          </p>
        </Verse>
      </Columns>
    </Layout>
  );
}
